<template>
  <!-- 品牌档案 -->
  <div>
    <div v-if="isShow == 0">
      <bread-crumb :crumbData="['商品管理']" :chooseCrumbData="1" reloadComponent="CommodityFileVue"></bread-crumb>


      <!-- <search-case :searchData="searchData" @inquireBtnClick="inquireBtnClick"></search-case> -->
      <el-row style="border-bottom: 1px solid #eee;padding:0px 20px;">
        <el-col :span="8" style="display:flex">
          <span>日期范围:</span>
          <el-date-picker v-model="t1" style="width:300px" type="daterange" value-format="yyyy-MM-dd" range-separator="至"
            start-placeholder="开始日期" end-placeholder="结束日期">
          </el-date-picker>
        </el-col>
        <el-col :span="2">
          <el-select v-model="query.is_show" placeholder="状态" clearable filterable>
            <el-option v-for="(item, index) in firstOptiones" :key="index" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="2">
          <el-select v-model="query.product_type" placeholder="类型" clearable filterable>
            <el-option v-for="(item, index) in secondOptiones" :key="index" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="2">
          <el-select v-model="query.product_label" placeholder="标签" clearable filterable>
            <el-option v-for="(item, index) in thirdSelectOptiones" :key="index" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="4">
          <el-input placeholder="输入要查询的内容" v-model="query.search"></el-input>
        </el-col>
        <el-button type="success" size="mini" @click="handleQuery">
          <div style="display: flex; align-items: center; font-size: 14px">
            <span class="spriteChart_inquire"></span>
            <span style="margin-left: 5px">查询</span>
          </div>
        </el-button>
      </el-row>
      <!-- 表格操作按钮 -->
      <div class="handleBtnClass">
        <div style="margin-right: 5px">
          <el-button type="danger" size="mini" @click="isImportGoodsDialog = !isImportGoodsDialog">
            <div style="display: flex; align-items: center; font-size: 14px">
              <span class="spriteChart_add"></span>
              <span style="margin-left: 5px">导入商品</span>
            </div>
          </el-button>
        </div>
        <div style="margin-right: 5px">
          <el-button type="primary" size="mini" @click="isShow = 2">
            <div style="display: flex; align-items: center; font-size: 14px">
              <span class="spriteChart_add"></span>
              <span style="margin-left: 5px">新增</span>
            </div>
          </el-button>
        </div>
        <div style="margin-right: 5px">
          <el-button type="danger" size="mini" @click="deleteList">
            <div style="display: flex; align-items: center; font-size: 14px">
              <span class="spriteChart_delete"></span>
              <span style="margin-left: 5px">批量删除</span>
            </div>
          </el-button>
        </div>
        <div style="margin-right: 5px" @click="exportClick">
          <el-button type="primary" size="mini">
            <div style="display: flex; align-items: center; font-size: 14px">
              <span class="spriteChart_daochu"></span>
              <span style="margin-left: 5px">导出商品</span>
            </div>
          </el-button>
        </div>
        <div style="margin-right: 5px">
          <el-button type="danger" size="mini">
            <div style="display: flex; align-items: center; font-size: 14px">
              <span class="spriteChart_add"></span>
              <span style="margin-left: 5px">一键导入</span>
            </div>
          </el-button>
        </div>
      </div>

      <!-- 导入excel -->
      <el-dialog title="提示" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
        <div>
          <el-upload class="upload-excel" action="https://jsonplaceholder.typicode.com/posts/"
            :before-upload="beforeUpload" :on-success="handleUploadSuccess" :headers="{ Authorization: token }"
            :data="{ param1: 'value1', param2: 'value2' }">
            <el-button size="medium" type="primary">上传Excel</el-button>
          </el-upload>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="importExcel">确 定</el-button>
        </span>
      </el-dialog>
      <!-- 一键导入 -->
      <el-dialog title="提示" :visible.sync="isEasyImport" width="30%" :before-close="handleClose">
        <span>确认导入？</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="isEasyImport = false">取 消</el-button>
          <el-button type="primary" @click="isEasyImport = false">确 定</el-button>
        </span>
      </el-dialog>

      <!-- 主要内容 -->
      <div class="file_form">
        <el-row>
          <el-col :span="4">
            <!-- 文件夹处 -->
            <List :classList="classList" @allFile="allFile" @labelClick="labelClick" />
          </el-col>
          <el-col :span="20">
            <!-- 表格 -->
            <el-table ref="multipleTable" border :data="tableData" v-loading="isLoading" tooltip-effect="dark"
              style="width: 100%" @selection-change="handleSelectionChange">
              <el-table-column type="selection" align="center">
              </el-table-column>
              <el-table-column prop="id" sortable label="ID" align="center">
              </el-table-column>
              <el-table-column sortable label="图片" align="center">
                <template slot-scope="scope">
                  <el-image :src="'http://192.168.3.3' + scope.row.thumbnail" />
                </template>
              </el-table-column>
              <el-table-column prop="create_time" sortable label="创建日期" align="center">
              </el-table-column>
              <el-table-column prop="number" sortable label="编号" align="center">
              </el-table-column>
              <el-table-column prop="product_name" label="名称" align="center">
              </el-table-column>
              <el-table-column prop="unit" label="单位" align="center">
              </el-table-column>
              <el-table-column prop="cid" label="分类" align="center">
              </el-table-column>
              <el-table-column prop="market_price" sortable label="市场价" align="center">
              </el-table-column>
              <el-table-column prop="sales_price" sortable label="销售价" align="center">
              </el-table-column>
              <el-table-column prop="now_inventory" sortable label="当前库存" align="center">
              </el-table-column>
              <el-table-column prop="total_inventory" sortable label="可用库存" align="center">
              </el-table-column>
              <el-table-column prop="sort" sortable label="排序" align="center">
              </el-table-column>
              <el-table-column prop="sales_strategy" label="销售策略" align="center">
              </el-table-column>
              <el-table-column label="是否下架" align="center">
                <template slot-scope="scope">
                  <el-button :type="scope.row.is_putaway ? 'success' : ''"
                    @click="putawayClick(scope.row.id, scope.row.is_putaway)">
                    {{ scope.row.is_putaway ? "下架" : "上架" }}</el-button>
                </template>
              </el-table-column>
              <el-table-column label="修改详情" align="center">
                <template slot-scope="scope">
                  <button class="details" @click="detailsClick(scope.row, scope.$index)">
                    修改详情
                  </button>
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                  <i class="el-icon-edit poiner" @click="handleEdit(scope.row.id)"></i>
                  <span style="margin: 0 10px"></span>
                  <i class="el-icon-delete poiner" @click="handleDelete(scope.row.id)"></i>
                </template>
              </el-table-column>
            </el-table>



            <div class="tableBottom">
              <span v-if="isResult">显示第&nbsp;{{
                tableData.length == 0 ? 0 : (currentPage - 1) * 20 + 1
              }}&nbsp;至&nbsp;{{
  (currentPage - 1) * 20 + tableData.length
}}&nbsp;项结果，共&nbsp;{{ tabletotalNum }}&nbsp;项</span>
              <span v-if="!isResult">显示&nbsp;0&nbsp;项结果，共&nbsp;0&nbsp;项</span>
              <div style="
                  display: flex;
                  justify-content: flex-end;
                  align-items: center;
                ">
                <el-pagination background layout="prev, pager, next" :total="tabletotalNum" :current-page="currentPage"
                  @current-change="currentChange">
                </el-pagination>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <el-dialog title="商品资料库" :visible.sync="isImportGoodsDialog" width="70%" :before-close="handleClose">
        <importGoodsVue></importGoodsVue>
      </el-dialog>
    </div>
    <redactGoods v-if="isShow == 1" @close="onClose" :passPageId="passPageId"></redactGoods>
    <addGoods v-if="isShow == 2" @close="onClose"> </addGoods>
    <UpdateInfo v-if="isShow === 3" @close="onClose" />
  </div>
</template>
<script>
import { xlixs } from "@/until/xlsx";
import List from "@/components/main/list";
export default {
  name: "commodityFile",
  components: {
    importGoodsVue: () => import("./childrens/importGoodsVue.vue"),
    redactGoods: () => import("./childrens/redactGoods.vue"),
    addGoods: () => import("./childrens/addGoods.vue"),
    UpdateInfo: () => import("./childrens/updateInfo.vue"),
    List,
  },
  data() {
    return {
      isShow: 0,
      firstOptiones: [
        {
          label: "未上架",
          value: 0
        }, {
          label: "已上架",
          value: 1
        }
      ],
      secondOptiones: [
        {
          "label": "普通商品",
          value: 0
        },
        {
          "label": "同城商品",
          value: 1
        },
        {
          "label": "虚拟商品",
          value: 2
        },
        {
          "label": "赠品",
          value: 3
        }
      ],
      thirdSelectOptiones: [
        {
          "label": "精选",
          "value": 2
        },
        {
          "label": "新品",
          "value": 1
        },
        {
          "label": "推荐",
          "value": 0
        }
      ],
      t1: "",
      query: {
        firstTime: "",
        lastTime: "",
        search: "",
        is_show: "",
        product_type: "",
        product_label: "",
        commercial_id: localStorage.getItem("commercial_id") ||
          sessionStorage.getItem("commercial_id")
      },
      searchData: {
        date: true,
        isFirstSelect: true,
        firstSelectPlaceholder: "状态",
        isSecondSelect: true,
        secondSelectPlaceholder: "类型",
        firstOptiones: [
          {
            label: "未上架",
            value: 0
          }, {
            label: "已上架",
            value: 1
          }
        ],
        secondOptiones: [
          {
            "label": "普通商品",
            value: 0
          },
          {
            "label": "同城商品",
            value: 1
          },
          {
            "label": "虚拟商品",
            value: 2
          },
          {
            "label": "赠品",
            value: 3
          }
        ],
        thirdSelectOptiones: [
          {
            "label": "精选",
            "value": 2
          },
          {
            "label": "新品",
            "value": 1
          },
          {
            "label": "推荐",
            "value": 0
          }
        ],
        isThirdSelect: true,
        thirdSelectPlaceholder: "标签",
        isInquireValue: true,
        inquirePlaceholder: "输入要查询的内容",
        isInquireBtn: true,
      },

      passPageId: "",

      formInline: {
        date1: "",
        date2: "",
        region1: "",
        region2: "",
        region3: "",
        QueryContent: "",
      },
      dialogVisible: false,
      token: "Bearer token_value",
      selectionNum: 0,
      isEasyImport: false,
      isFileShow: false,
      isLoading: true,
      tableData: [],
      multipleSelection: [],
      isResult: true,
      tabletotalNum: 0,
      currentPage: 1,
      currentLength: 20,

      isImportGoodsDialog: false,
      classList: [],
      commercial_id: "",

      cid: 0,
    };
  },
  mounted() {
    this.commercial_id =
      localStorage.getItem("commercial_id") ||
      sessionStorage.getItem("commercial_id");
    this.getClassList();
    this.inquireList();
  },
  computed: {},
  methods: {
    async handleQuery() {
      let query = this.query;
      query.firstTime = this.t1[0];
      query.lastTime = this.t1[1];
      this.$http
        .post("/product/search", query)
        .then((res) => {
          this.tableData = res.data.data;
          this.tabletotalNum = res.data.count;
        });


    },
    // 多选数据 -- 表格
    handleSelectionChange(val) {
      this.multipleSelection = val;
      // this.selectionNum = val.length++;
    },
    // 分页 -- 当前页改变传值
    currentChange(val) {
      this.currentPage = val;
      this.inquireList();
    },
    // 表格数据
    inquireList() {
      this.$http
        .post("/product/lst", {
          currentPage: this.currentPage,
          currentLength: this.currentLength,
          cid: this.cid,
          commercial_id: this.commercial_id,
        })
        .then((res) => {
          this.isLoading = false;
          this.tableData = res.data.data;
          this.tabletotalNum = res.data.count;

        });
    },
    allFile() {
      // this.isFileShow = !this.isFileShow;
      if (this.cid == 0) return;
      this.cid = 0;
      this.inquireList();
    },
    labelClick(id) {
      this.cid = id;
      this.inquireList();
    },
    //导出
    exportClick() {
      this.$confirm("每次导出最大商品数为10000条？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const res = await this.$http.get("/product/export");
          const arr = res.data.data;
          const headers = [
            "ID",
            "编号",
            "名称",
            "单位",
            "规格1",
            "规格2",
            "分类",
            "市场价",
            "销售价",
            "价格(V1)",
            "价格(V2)",
            "价格(V3)",
            "价格(V4)",
            "价格(V5)",
            "当前库存",
            "月销量",
          ];
          xlixs(arr, headers, "商品档案");
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消导出",
          });
        });
    },
    // 是否上架 -- 切换
    putawayClick(id, val) {
      this.$http
        .post(
          "/product/is_putaway",
          this.$qs.stringify({
            id: id,
            is_putaway: val,
          })
        )
        .then(() => {
          this.inquireList();
        });
    },
    getClassList() {
      this.$http
        .post("/Goods_Category/lst", {
          commercial_id: this.commercial_id,
        })
        .then((res) => {
          this.classList = res.data.data;
        });
    },

    // 查询传值
    inquireBtnClick(e) {
      this.inquireSearch(e);
      console.log(e);
    },
    // 后端数据 -- 查询
    inquireSearch(e) {
      let product_label = "";
      let product_type = "";
      if (e.thirdSelect == "精选") {
        product_label = 2;
      } else if (e.thirdSelect == "新品") {
        product_label = 1;
      } else {
        product_label = 0;
      }
      if (e.secondSelect == "普通商品") {
        product_type = 0;
      } else if (e.secondSelect == "同城商品") {
        product_type = 1;
      } else if (e.secondSelect == "虚拟商品") {
        product_type = 2;
      } else {
        product_type = 3;
      }
      this.$http
        .post("/product/search", {
          firstTime: e.dateRange[0],
          lastTime: e.dateRange[1],
          search: e.inquireValue,
          is_show: e.firstSelect == "已上架" ? 1 : 0,
          product_type,
          product_label,
          commercial_id: this.commercial_id,
        })
        .then((res) => {
          this.tableData = res.data.data;
          this.tabletotalNum = res.data.count;
        });
    },
    // 弹出框关闭
    handleClose(done) {
      done();
    },
    // 关闭子页面
    onClose(data) {
      this.isShow = data;
      this.inquireList();
    },
    // 编辑功能
    handleEdit(id) {
      this.passPageId = id;
      this.isShow = 1;
    },
    // 删除 -- 批量
    deleteList() {
      this.$confirm("是否删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        if (this.multipleSelection != "") {
          const idArr = this.multipleSelection.map((item) => {
            return item.id;
          });
          this.$http
            .post(
              "/product/del",
              this.$qs.stringify({
                id: idArr,
              })
            )
            .then(() => {
              this.inquireList();
            });
        } else {
          this.$message.error("请选择需删除的数据！");
        }
      });
    },
    // 删除 -- 单条
    handleDelete(id) {
      this.$confirm("是否删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$http
            .post(
              "/product/del",
              this.$qs.stringify({
                id,
              })
            )
            .then(() => {
              this.inquireList();
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    getChild(data) {
      console.log(data);
      this.isChildren = data;
    },
    onSubmit() { },
    importGoods() {
      console.log("导入商品");
      this.isChildren = 2;
    },
    deleteGoods() {
      console.log("批量删除");
    },
    importExcel() {
      console.log("导入Excel");
      this.dialogVisible = false;
    },
    beforeUpload(file) {
      // 检查文件类型和大小等
      const isExcel =
        file.type === "application/vnd.ms-excel" ||
        file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      const isLt10M = file.size / 1024 / 1024 < 10;

      if (!isExcel) {
        this.$message.error("只能上传 Excel 文件");
        return false;
      }
      if (!isLt10M) {
        this.$message.error("上传文件大小不能超过 10MB");
        return false;
      }

      return true;
    },
    handleUploadSuccess(response, file) {
      console.log(file);
      // 处理上传成功后的响应数据
      if (response.code === 0) {
        this.$message.success("上传成功");
        // do something with response
      } else {
        this.$message.error(response.msg);
      }
    },

    exportGoods() {
      if (this.selectionNum == 0 || this.selectionNum > 10000) {
        this.$message.info("每次导出最大商品数为10000条");
      } else {
        console.log("导出商品");
        this.dialogVisible = false;
      }
    },
    easyImport() {
      if (this.selectionNum == 0) {
        this.$message.info("请选择要导入的数据");
      } else {
        console.log("导出商品");
        this.isEasyImport = false;
      }
    },

    detailsClick(data) {
      this.isShow = 3;
      console.log(data);
    },
  },
};
</script>
<style scoped lang="less">
.file_form {
  padding: 10px;
}

.details {
  border: 0.5px solid #e0e3e9;
  background-color: #fff;
  padding: 5px;
  color: #737579;
  cursor: pointer;
}

.label {
  background-color: #ffeabf;
}

// /deep/ .el-dialog__header {
//   padding: 5px !important;
// }
/deep/ .el-dialog__title {
  font-size: 16px !important;
}

/deep/ .el-dialog__body {
  padding: 10px !important;
}

/deep/ .cell {
  padding: 0 !important;
}

// /deep/ .el-row {
//   display: flex;
//   align-items: center;
//   flex-wrap: nowrap;
//   white-space: nowrap;
//   font-size: 14px;
//   margin: 0 10px;
//   min-height: 32px;
// }

// /deep/ .el-col {
//   margin: 0 10px;
// }
</style>

